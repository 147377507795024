import React from "react";
import useWindowDimensions from "../../common/getWindowDimensions";
import {
  Typography,
  Box,
  Container,
  TextField,
  Button,
  FormControl,
  Checkbox,
  Divider,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GoogleSVG from "../../../assets/img/auth/google.svg";
import MicrosoftSVG from "../../../assets/img/auth/microsoft.svg";
import { useParams, useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { message } from "antd";

const SignupComponent = () => {
  const [togglePassword, setTogglePassword] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")

  const { height, width } = useWindowDimensions();
  const firstLastNameWidth =
     width <= 1440 && width > 824 ? "30vw" : "20vw";


  const { id } = useParams()
  const LOGIN_LINK = "/Login/"+id

  React.useEffect(() => {
    console.log(id)
  }, [])

  const navigate = useNavigate()
  const handleFirstName = (e) => {
    setFirstName(e.target.value)
  }

  const handleLastName = (e) => {
    setLastName(e.target.value)
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  const memberSignup = (e) => {

      e.preventDefault()
      const nameRegex = /^[a-zA-Z0-9]+[a-zA-Z]+[a-zA-Z0-9]*$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
     
    
        if (!nameRegex.test(firstName) && firstName.length < 3) {
          message.error("First Name is invalid.")
          return;
        }
        if (!nameRegex.test(lastName) && lastName.length < 3) {
          message.error("Last Name is invalid.")
          return;
        }
    
        if (!emailRegex.test(email)) {
          message.error("Invalid email address. Please enter a valid email address.")
          return;
        }
        if(password.length < 8){
          message.error("password should be 8 character long")
          return;
        }
      let body={
        isOrganizer:false,
        OrganizationId: id,
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password
      }
      fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/signup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify(body),
      cache: "default",
    })
      .then(function (response) {
        console.log(response);
        if(response.status == 200 || response.status == 201){
           (navigate("/Login/" + id, {state: {
            emailAddress: email,
            password:password
          } }))
        }else{
          if(lastName == "" || firstName == ""){
            message.error("First name and last name are required !")
           
          }else{
            message.error("Organization with this email address already exists!")
           
          }
          } 
            return response.json();
      })
      .then(function (data) {
        console.log(data);
        return data;
      });

  }


  const handleGoogleLoginSuccess = (credentialResponse) => {
    const idToken = credentialResponse.credential;
    const requestBody = {
        id_token: idToken,
        isOrganizer:false,
    };
    // Send the POST request to your backend endpoint
    fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/google/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            // Handle the response from the backend if needed
            console.log("google login data :",data);
            localStorage.setItem("UserId", data?.data?.id);
            localStorage.setItem("token", data?.data?.token);
            localStorage.setItem("email", data?.data?.email);
            localStorage.setItem("name", data?.data?.name);
            localStorage.setItem("isOrganizer", data?.data?.isOrganizer);
            window.location.href = "/";
        })
        .catch((error) => {
            // Handle errors that occurred during the fetch
            console.error('Error:', error);
        });
};

const handleGoogleLoginError = () => {
    console.log('Login Failed');
};

  return (
    // <Box
    //   sx={{
    //     height: "100%",
    //     width: "100%",
    //   }}
    // >
    //   <Typography>Sign Up for Chatbot AI Framework</Typography>
    //   <Typography variant="h3" fontWeight={"bold"}>
    //     Create Free Account
    //   </Typography>
    //   <Container
    //     sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    //   >
    //     <Typography fontWeight={"bold"} mr={1}>
    //       Start generating{" "}
    //     </Typography>
    //     <Typography sx={{ color: "#295598", fontWeight: "bold" }}>
    //       AI Chatbots
    //     </Typography>{" "}
    //     <Typography fontWeight={"bold"} ml={1}>
    //       Now
    //     </Typography>
    //   </Container>
    //   <Container sx={{ display: "grid", width: "70%", gap: "2vw", mt: "5vh" }}>
    //   <form onSubmit={memberSignup} style={{display:"flex", flexDirection:"column", gap:"5vh"}}>
    //     <FormControl color={"primary"}>
    //       <Typography align="left" fontWeight={"bold"}>Email address</Typography>
    //       <TextField
    //         id="my-input"
    //         placeholder="Write your email address here ..."
    //         aria-describedby="my-helper-text"
    //         type="email"
    //         onChange={handleEmail}
    //         InputProps={{
    //             notchedOutline: "none",
    //         }}
    //         sx={{
    //           border: "none",
    //           borderRadius: "3vw",
    //           "& .MuiInputBase-input": { backgroundColor: "#E1F4FF" },
    //         }}
    //         required
    //       />
    //     </FormControl>
    //     <Box sx={{ display: "flex", justifyContent: "space-between", gap:"2vw" }}>
    //       <FormControl color={"primary"}>
    //         <Typography align="left" fontWeight={"bold"}>First Name</Typography>
    //         <TextField
    //           id="my-input"
    //           placeholder="Write your first name here ..."
    //           aria-describedby="my-helper-text"
    //           type="text"
    //           onChange={handleFirstName}
    //           sx={{
    //             width:  firstLastNameWidth,
    //             borderRadius: "3vw",
    //             "& .MuiInputBase-input": { backgroundColor: "#E1F4FF" },
    //           }}
    //           required
    //         />
    //       </FormControl>
    //       <FormControl color={"primary"}>
    //         <Typography align="left" fontWeight={"bold"}>Last Name</Typography>
    //         <TextField
    //           id="my-input"
    //           placeholder="Write your last name here ..."
    //           aria-describedby="my-helper-text"
    //           type="text"
    //           onChange={handleLastName}
    //           sx={{
    //             width: firstLastNameWidth,
    //             borderRadius: "3vw",
    //             "& .MuiInputBase-input": { 
    //               backgroundColor: "#E1F4FF",
    //                },

    //           }}
    //           required
    //         />
    //       </FormControl>
    //     </Box>
    //     <FormControl>
    //       <Typography align="left" fontWeight={"bold"}>Password</Typography>
    //       <TextField
    //         id="my-input"
    //         type={togglePassword ? "text" : "password"}
    //         placeholder="Write your password here ..."
    //         aria-describedby="my-helper-text"
    //         onChange={handlePassword}
    //         sx={{
    //           borderRadius: "3vw",
    //           "& .MuiInputBase-input": { backgroundColor: "#E1F4FF" },
    //         }}
    //         required
    //         inputProps={{
    //           minlength:4
    //         }}
    //         InputProps={{
    //           endAdornment: (
    //             <InputAdornment position="end" sx={{cursor:"pointer"}}>
    //               {" "}
    //               {togglePassword ? (
    //                 <Visibility
    //                   className="cursor_pointer"
    //                   onClick={() => {
    //                     setTogglePassword(false);
    //                   }}
    //                 />
    //               ) : (
    //                 <VisibilityOff
    //                   onClick={() => {
    //                     setTogglePassword(true);
    //                   }}
    //                 />
    //               )}
    //             </InputAdornment>
    //           ),
    //         }}
    //       />
    //     </FormControl>
    //     <Container
    //       sx={{
    //         display: "flex",
    //         justifyContent: "flex-start",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Checkbox size="large" />
    //       <Typography>
    //         I agree to terms of service and privacy policy
    //       </Typography>
    //     </Container>
    //     <Container
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         flexDirection: "column",
    //       }}
    //     >
    //       <Button
    //         variant="contained"
    //         size="large"
    //         type="submit"
    //         sx={{
    //           alignSelf: "center",
    //           width: "220px",
    //           backgroundColor: "#295598",
    //           border: "solid",
    //           borderColor: "black",
    //           fontWeight: "bold",
    //           color: "#ffffff",
    //         }}
    //       >
    //         Create Account
    //       </Button>
    //       <Typography color={"grey"} sx={{my:"20px"}}>
    //         Already have an account? <Link to={"/login"}>Login</Link> here.
    //       </Typography>
    //     </Container>
    //     <Divider>OR</Divider>
    //     <Typography variant="h6">Sign up with</Typography>
    //     <Container sx={{display:"flex", justifyContent:"space-evenly"}}>
    //     <GoogleOAuthProvider clientId="364039229228-1iv7i68nnhbtg4vn059kjuc1qnevn60d.apps.googleusercontent.com">
    //         <GoogleLogin
    //           onSuccess={handleGoogleLoginSuccess}
    //           onError={handleGoogleLoginError}
    //           width='12vw'
    //           size='large'
    //           text='signin'
    //           shape='square'
    //           theme='filled_black'

    //         />
    //       </GoogleOAuthProvider>
    //     {/* <Button variant="outlined" startIcon={<img src={MicrosoftSVG} height="20px" width="20px"/>} sx={{
    //           alignSelf: "center",
    //           width: "12vw",
    //           backgroundColor: "white",
    //           border: "solid",
    //           borderColor: "black",
    //           fontWeight: "bold",
    //           color: "#000000",
    //         }}>
    //       Microsoft
    //     </Button> */}
    //     </Container>
    //     </form>
    //   </Container>
    // </Box>
    <div className="flex flex-col justifycenter items-center gap-8 py-4">
    <img src="/sequential-auth-logo.png" className="lg:w-[14%] h-14" />
    <div className="relative lg:w-[65%] h-[600px]  max-w-[812px] flex flex-col items-center gap-2 py-6 px-6 bg-white rounded-lg">
      <img src="/auth-container-bg.svg" className="absolute top-14 -z-[10px]" />
      <h1 className="text-xl font-bold">Sign Up</h1>
      <p className="text-black text-opacity-80">
        Welcome back to Avatare. Let’s setup your account
      </p>
      <div className="mt-5">
        <GoogleOAuthProvider clientId="364039229228-1iv7i68nnhbtg4vn059kjuc1qnevn60d.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginError}
            width="12vw"
            size="large"
            text="Continue with Google"
            shape="square"
          />
        </GoogleOAuthProvider>
      </div>
      <div className="lg:px-28 mt-5 w-full">
        <div className="flex w-full justify-center items-center gap-2">
          <div className="w-full h-[0.5px] min-w-[25%] bg-black bg-opacity-20"></div>
          <p className="text-black text-opacity-40">OR</p>
          <div className="w-full h-[0.5px] min-w-[25%] bg-black bg-opacity-20"></div>
        </div>
        <div className="flex gap-6">
        <div className="relative w-1/2 bg-[#F7F8FC]  rounded-[4px] mt-5">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <img src="/user-icon.svg" />
          </div>
          <input
            type="text"
            style={{
              border: "0.5px solid rgba(0, 0, 0, 0.50)",
              borderRadius: "4px",
            }}
            placeholder="First Name"
            onChange={handleFirstName}
            className="w-full p-3 pl-12 border bg-[#F7F8FC]  custom-outline"
          />
        </div>
        <div className="relative w-1/2 bg-[#F7F8FC]  rounded-[4px] mt-5">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <img src="/user-icon.svg" />
          </div>
          <input
            type="text"
            style={{
              border: "0.5px solid rgba(0, 0, 0, 0.50)",
              borderRadius: "4px",
            }}
            placeholder="Last Name"
            onChange={handleLastName}
            className="w-full p-3 pl-12 border bg-[#F7F8FC]  custom-outline"
          />
        </div>
        </div>
        <div className="relative w-full bg-[#F7F8FC]  rounded-[4px] mt-5">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <img src="/email-icon.svg" />
          </div>
          <input
            type="text"
            style={{
              border: "0.5px solid rgba(0, 0, 0, 0.50)",
              borderRadius: "4px",
            }}
            value={email}
            required
            onChange={handleEmail}
            placeholder="Email Address"
            className="w-full p-3 pl-12 border bg-[#F7F8FC]  custom-outline"
          />
        </div>
        <div className="relative w-full bg-[#F7F8FC]  rounded-[4px] mt-6">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <img src="/password-icon.svg" />
          </div>
          <input
            type="password"
            style={{
              border: "0.5px solid rgba(0, 0, 0, 0.50)",
              borderRadius: "4px",
            }}
            placeholder="Password"
            value={password}
            onChange={handlePassword}
            className="w-full p-3 pl-12 border bg-[#F7F8FC]  custom-outline"
          />
        </div>
        <div className="w-full mt-2">
          <div className="flex items-center gap-1.5">
          <Checkbox size="medium" sx={{padding:"0px !important" }} className="flex self-start mt-1" />
          <h2 className="text-sm text-black text-opacity-80">We won’t share your info with anyone. We won’t be sending promotional emails. For details, see  <span className="underline underline-offset-4">Privacy Policy. </span></h2>
          </div>
        </div>
        <div className="w-[80%] lg:w-[63%] mt-8  absolute cursor-pointer transition ease-out flex flex-col justify-start items-center gap-4">
         <button className="primary px-8 py-2 cursor-pointer" onClick={memberSignup}>Create Account</button>
          <p  className="w-full text-wrap text-center">Already have an account? <span onClick={(e)=> navigate("/login")} className="font-semibold text-[#20556E] underline underline-offset-[6px]"> Sign In</span> here</p>
        </div> 
      </div>
    </div>
  </div>

  );
};

export default SignupComponent;
