import React from "react";

import { Typography } from "antd";

const { Paragraph } = Typography;

const PlaybookRowTwo = ({ blocks, last ,headingCircleTwo,subHeadingCircleTwo}) => {
  return (
    <div className="w-full mt-[100px] ">
      <div className="flex flex-row justify-between items-center w-full">
        {blocks.map((block, index) => {
          return (
            <div
              key={index}
              className={`relative    z-[-10] ml-[-10px] w-full h-12 ${block.lineClass}`}
            >
              <div
                className={` ${block.circleClass} text-white font-semibold text-[21px] h-[54px] w-[54px] border-white border-[2px] absolute left-5 rounded-full flex justify-center items-center p-2`}
              >
                {block.number}
                <div
                  className={`absolute left-0 ${
                    index % 2 === 0 ? "-top-[60px]" : "top-[60px]"
                  }`}
                >
                  <h1
                    style={{ color: block.textColor, fontSize: "14px" }}
                    className={`absolute w-[120px] left-10 -top-1 underline underline-offset-auto`}
                  >
                    <Paragraph
                      ellipsis={{
                        rows: 3,
                        expandable: false,
                        
                      }}
                    >
                      {block.text}
                    </Paragraph>
                  </h1>
                  <div
                    style={{ background: block.lineColor }}
                    className={`w-[3px] h-[52px] ml-5`}
                  ></div>
                </div>
              </div>

              {index > 0 && (
                <div className={`h-12 w-full flex flex-row items-center`}>
                  <div
                    className={`-ml-[46px] z-20 h-12 w-12 custom-arrow -rotate-90 ${block.arrowClass}`}
                  ></div>
                </div>
              )}
            </div>
          );
        })}

        {last && (
          <div className=" absolute  top-[370px] flex flex-row items-center justify-end rotate-180">
            <div className=" absolute -right-[89px] -top-[166px]">
              <img className="h-[400px]" src="/1ST.svg" alt="curve" />
              <div className="bg-tracker-arrow-1 absolute right-[41px] top-[271px] ml-[0.5px] z-20 h-11 w-12 custom-arrow rotate-90"></div>

              {last && (
                <div
                  className={`rotate-180 absolute -right-[40px] top-[156px] z-10 rounded-full border-[2px] border-white max-w-[120px] max-h-[120px] min-w-[120px] min-h-[120px] grid grid-cols-1`}
                >
                  <h1 className=" col-span-1 flex justify-center items-center bg-[#386FA0] rounded-tr-full rounded-tl-full text-center text-[9px] pt-6 px-3 text-white border-b-white border-b-[2px]">
                    {headingCircleTwo}
                  </h1>
                  <h2 className=" bg-[#D9D9D9] rounded-br-full rounded-bl-full col-span-1 flex justify-center items-center pb-6 px-3 text-[#386FA0] font-semibold text-xl">
                    {subHeadingCircleTwo}
                  </h2>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaybookRowTwo;
