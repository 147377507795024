import * as React from "react";
import { OverallProgress } from "../components/Home/OverallProgress";
import TrackorComponent from "../components/Home/Tracker";
import { TopBar } from "../components/CommonComponent/Topbar";

const part1 = [
  {
    number: "01",
    text: "Identify Problems & Trends",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "02",
    text: "Evaluate Problems & Trends",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "03",
    text: "Select the Focus Problem",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "04",
    text: "Identify Pain Points",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "05",
    text: "Determine Jobs to be Done",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
];

const part2 = [
  {
    number: "10",
    text: "Quick Market Analysis",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "09",
    text: "Idea Testing with Mini Focus Group",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "08",
    text: "Strategic Innovation Statement",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "07",
    text: "Identify a Potential Solution to the Problem",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "06",
    text: "Profitable Market Need",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part3 = [
  {
    number: "11",
    text: "Entrepreneur Essentials",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "12",
    text: "Document and Define the Company's Overall Vision, Mission, and Core Values",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "13",
    text: "Basic Project Management",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "14",
    text: "Keep Separate Spending Records",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "15",
    text: "Secure Mentorship",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "16",
    text: "Surround Yourself with 6 Key Advisors",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part4 = [
  {
    number: "24",
    text: "Create a Customer Avatar",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "23",
    text: "10 Types of Innovation",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "22",
    text: "Decide on One of 'Three Horizons'",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "21",
    text: "Primary Research on Market Characteristics",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "20",
    text: "Competitive Analysis",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "19",
    text: "Primary Research on Market Size - Identify Total Addressable Market (TAM)",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "18",
    text: "Feedback Loop for Required Features of MVP",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "17",
    text: "Identify the Required Features for a Minimum Viable Product (MVP)",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part5 = [
  {
    number: "25",
    text: "Identify Pilot Clients",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "26",
    text: "Calculate the Cost for MVP Development",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "27",
    text: "Select a Funding Option",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "28",
    text: "Define the Unique Selling Propositions (USP's)",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "29",
    text: "Understand the Customer Buying Process",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
];

const part6 = [
  {
    number: "32",
    text: "Develop the Minimal Viable Product (MVP) or a Prototype to Show Potential Customers",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "31",
    text: "Establish Designs and Wireframes/Prototypes",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "30",
    text: "Create Roadmap for MVP Development",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part7 = [
  {
    number: "33",
    text: "Product Testing (Customer) with Feedback Collection",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "34",
    text: "Map the Customer Use Journey",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "35",
    text: "Refine MVP Based on Customer Feedback",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "36",
    text: "Validate Customer Avatar(s)",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
];

const part8 = [
  {
    number: "39",
    text: "Prepare Tech Infrastructure and Security",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "38",
    text: "Assessing Intellectual Property",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "37",
    text: "Business Setup",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part9 = [
  {
    number: "40",
    text: "Finalize Your CAP Table",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "41",
    text: "Create Org Chart",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "42",
    text: "Establish Operations Agreement",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "43",
    text: "Round Out the Founding Team",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "44",
    text: "Set Up Employee Participation Program (If Applicable)",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
];
const part10 = [
  {
    number: "49",
    text: "Ensure Environmental, Social, & Governance (ESG) Compliance (If Applicable)",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "48",
    text: "Establish an Online Footprint",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "47",
    text: "Define Your Brand",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "46",
    text: "Create the Business Model Canvas",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "45",
    text: "Determine Your Competitive Moat(s)",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part11 = [
  {
    number: "50",
    text: "Identify All the Players in Your Ecosystem",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "51",
    text: "Build Supply Chain (If Applicable)",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "52",
    text: "Test Distribution Stream",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "53",
    text: "Identify and Approach Pilot Partnerships",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
];

const part12 = [
  {
    number: "60",
    text: "Develop Generic External SOP's",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "59",
    text: "Set Up Lean PMO",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "58",
    text: "Continue Testing and Bug-Fixing (UAT Testing)",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "57",
    text: "Re-work MVP into a Final Product Based on Customer Feedback",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "56",
    text: "Stress Test and Bug Fix",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "55",
    text: "Create a Customer Feedback Mechanism (Systemized and Automatic)",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "54",
    text: "Consider Pre-Selling Your Product",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part13 = [
  {
    number: "61",
    text: "Identify Potential Business Models",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "62",
    text: "Rank Business Models on “Short List”",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "63",
    text: "Test Business Model with Customer Feedback",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
];

const part14 = [
  {
    number: "70",
    text: "Prepare Daily, Weekly, and Monthly Reports",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "69",
    text: "Set Up Data Warehouse (if applicable)",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "68",
    text: "Determine & Deploy Tool Stack",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "67",
    text: "Define the Top 20 KPI",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "66",
    text: "Design Operating Model",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "65",
    text: "Create Accelerator Roadmap",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "64",
    text: "Build Financial Model",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part15 = [
  {
    number: "71",
    text: "Create a Product Marketing Plan",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "72",
    text: "Build Content Generator",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "73",
    text: "Build Sales Funnel",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "74",
    text: "Set Up Customer Care",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
];

const part16 = [
  {
    number: "78",
    text: "Create Personal AI Bot",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "77",
    text: "Strategies to guide growth throughout your business",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "76",
    text: "Create Entrepreneur Scorecard",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "75",
    text: "Constantly Evaluate Further Growth and Expansion Options",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
];

const part17 = [
  {
    number: "79",
    text: "Improve Management of the Sales Funnel",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "80",
    text: "Define & Implement Best Practices for Each Function: Sales",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "81",
    text: "Improve and Revise Your Sales Playbook",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "82",
    text: "Create Your Sales Team Template",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "83",
    text: "Create a Follow-Up Machine",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "84",
    text: "Create Revenue Multiplier",
    textColor: "#37315D",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
];

const part18 = [
  {
    number: "90",
    text: "Build Demo Demand Sequence",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "89",
    text: "Launch PR and Paid Marketing Campaign",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "88",
    text: "Prepare Press List",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "87",
    text: "Create Lead Generator Outline",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "86",
    text: "Create Content Pipeline",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "85",
    text: "Define & Implement Best Practices for Each Function: Marketing",
    textColor: "#37315D",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
];

const part19 = [
  {
    number: "91",
    text: "Build Social Proof",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "92",
    text: "Build Webinar Converter (If Applicable)",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "93",
    text: "Build Trial Closer (If Applicable)",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "94",
    text: "Focus on Cross-Channel Marketing Mix that Works",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "95",
    text: "Build Brand and Execute PR Strategy",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
];

const part20 = [
  {
    number: "100",
    text: "Optimize Customer Acquisition Costs (CAC) vs. Customer Lifetime Value (CLV)",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "99",
    text: "Analyze Progress Toward Financial Targets",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "98",
    text: "Ramp Up Accounting Systems",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "97",
    text: "Establish Proper Financial Compliance",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "96",
    text: "Define & Implement Best Practices for Each Function: Finance & Accounting",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part21 = [
  {
    number: "101",
    text: "Define & Implement Best Practices for Each Function: Human Capital",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "102",
    text: "Fill Out Org Chart/Hiring",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "103",
    text: "Refine and Improve SOPs",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "104",
    text: "Evaluate Pay Scales and Human Capital",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "105",
    text: "Create and Maintain Company Culture",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "106",
    text: "Implement Ongoing Knowledge Sharing",
    textColor: "var(--Turquise, #6CA6C4)",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
];

const part22 = [
  {
    number: "111",
    text: "Achieve Product-Market-Fit",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "110",
    text: "Enhance UI/UX According to Usability Tests",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "109",
    text: "Product Roadmap",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "108",
    text: "Analyze Customer Engagement with the Product",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "107",
    text: "Define & Implement Best Practices for Each Function: Product",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];

const part23 = [
  {
    number: "112",
    text: "Define & Implement Best Practices for Each Function: IT & Dev",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1", 
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "113",
    text: "Engage Third Party Auditors for SOC, PCI, PEN Test, etc.",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2", 
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "114",
    text: "Improve Tech Stack’s Scalability, Availability, Speed, and Security",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
    circleClass: "bg-tracker-circle-3", 
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
];

const part24 = [
  {
    number: "116",
    text: "Improve Customer Care Processes to Maximize NPS",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "115",
    text: "Define & Implement Best Practices for Each Function: Customer Support",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2", 
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
];

const part25 = [
  {
    number: "117",
    text: "Define & Implement Best Practices for Each Function: Operations",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "118",
    text: "Build Local Logistics Value Streams",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "119",
    text: "Increase Facility Capacity",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "120",
    text: "Perform Legal Check of Business Model and Key Documents",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "121",
    text: "Dig Deeper Using Ad-hoc Reports for Each Function",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "122",
    text: "Navigate Using Daily, Weekly, and Monthly Reports",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "123",
    text: "Re-design the Operating Model According to the Data",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-1",
  },
];

const part26 = [
  {
    number: "130",
    text: "Implementing the OKR Framework",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "129",
    text: "Boost Workforce Agility",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "128",
    text: "Automate Important Manual Processes",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "127",
    text: "Optimize CRM",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "126",
    text: "Enhance Payment Mix, Fees, Checkout Funnel, and Fraud Prevention",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-2",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
  {
    number: "125",
    text: "Re-assess Suppliers and Partners",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
    circleClass: "bg-tracker-circle-3",
    arrowClass: "bg-tracker-arrow-2",
    lineClass: "bg-tracker-line-2",
  },
  {
    number: "124",
    text: "Eliminate Operational Bottlenecks",
    textColor: "#2B2649",
    lineColor: "#37315D",
    circleClass: "bg-tracker-circle-1",
    arrowClass: "bg-tracker-arrow-1",
    lineClass: "bg-tracker-line-1",
  },
];









const Home = () => {
  return (
    <>
      <TopBar text="My Progress" />
      <OverallProgress />
      <div className="flex flex-col justify-center self-center items-center mt-[6%] w-full lg:w-[86%]   relative">
        <div className="absolute top-10 left-[-62px] transform -rotate-90 self-start text-[#7FB04F] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px", fontStyle: "bold" }}>1</span>

          <span className="ml-10">Spark & Explore</span>
        </div>

        <div
          style={{ background: "rgba(127, 176, 79, 0.20)" }}
          className="absolute -top-14 ml-[150px] px-20  flex justify-center items-center h-[580px] w-full"
        ></div>

        <div className="w-full z-4 px-[8%]  pl-[20%]">
          <TrackorComponent
            showFirst={true}
            key={1}
            part={part1}
            nextPart={part2}
            headingCircleOne="Exploring the Problem-E1"
            subHeadingCircleOne="Part 2"
            headingCircleTwo="Exploring Plan the Mission-E2"
            subHeadingCircleTwo="Part 3"
            last={true}
          />
        </div>

        <div
          style={{ background: "rgba(32, 85, 110, 0.12)" }}
          className="absolute -mt-[2500px] ml-[150px] px-20 flex justify-center items-center h-[1050px] w-full"
        ></div>

        <div
          style={{ background: "rgba(73, 121, 100, 0.19)" }}
          className="absolute -mt-[820px] ml-[150px] px-20 flex justify-center items-center h-[500px] w-full"
        ></div>
        <div
          style={{ background: "rgba(108, 166, 196, 0.19)" }}
          className="absolute mt-[250px] ml-[150px] px-20 flex justify-center items-center h-[500px] w-full"
        ></div>
        <div
          style={{ background: "rgba(73, 121, 100, 0.19)" }}
          className="absolute -mt-[-2850px] ml-[150px] px-20 flex justify-center items-center h-[2000px] w-full"
        ></div>
        {/* <div
          style={{ background: "rgba(32, 85, 110, 0.12)" }}
          className="absolute -mt-[-4600px] ml-[150px] px-20 flex justify-center items-center h-[400px] w-full"
        ></div> */}
        <div className="mt-[100px]  w-full z-4 px-[8%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            part={part3}
            nextPart={part4}
            headingCircleOne="Develop min feature set hypothesis"
            subHeadingCircleOne="Part 4"
            headingCircleTwo="MVP Planning"
            subHeadingCircleTwo="Part 5"
            last={true}
          />
        </div>
        <div className="absolute top-[900px] right-[-215px] transform -rotate-90 self-end text-[#5C94C6] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px", fontStyle: "bold" }}>2</span>
          <span className="ml-2">Questions & Uncover</span>
        </div>

        <div className="absolute top-[1750px] left-[-62px] transform -rotate-90 self-start leading-4 text-[#497964] font-bold  text-center mt-[15%]">
          <span style={{ fontSize: "32px", fontStyle: "bold" }}>3</span>
          <span className="ml-2">
             Experiment & Network
          </span>
        </div>
        <div className="absolute top-[2250px] right-[-168px] transform -rotate-90 self-end text-[#5C94C6] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px", fontStyle: "bold" }}>4</span>
          <span lassName="ml-2">Collaborate</span>
        </div>

        <div className="absolute top-[3500px] left-[-0px] transform -rotate-90 self-start text-[#497964] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px", fontStyle: "bold" }}>5</span>
          <span className="ml-2">Exit</span>
        </div>
        {/* <div className="absolute top-[4400px] right-[-195px] transform -rotate-90 self-end text-[#5C94C6] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px", fontStyle: "bold" }}>6</span>
          <span className="ml-2">Raising the Capital</span>
        </div> */}

        <div className=" mt-[100px]  w-full z-4 px-[8%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            key={3}
            part={part5}
            nextPart={part6}
            headingCircleOne="Develop MVP"
            subHeadingCircleOne="Part 6"
            headingCircleTwo="MVP Validation"
            subHeadingCircleTwo="Part 7"
            last={true}
          />
        </div>
        <div className=" mt-[100px] w-full z-4 px-[10%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            part={part7}
            nextPart={part8}
            headingCircleOne="Build Functions "
            subHeadingCircleOne="Part 8"
            headingCircleTwo="Assemble Core Teams"
            subHeadingCircleTwo="Part 9"
            last={true}
          />
        </div>
        <div className="  mt-[100px] w-full z-4 px-[10%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            part={part9}
            nextPart={part10}
            headingCircleOne="Competitive positioning & Establish Presence"
            subHeadingCircleOne="Part 10"
            headingCircleTwo="Build Industry connections"
            subHeadingCircleTwo="Part 11"
           
            last={true}
          />
        </div>
        <div className="  mt-[100px] w-full z-4 px-[10%] pl-[20%]">
          <TrackorComponent
            showFirst={false}
            part={part11}
            nextPart={part12}
            headingCircleOne="Product ready for “prime time”"
            subHeadingCircleOne="Part 12"
            headingCircleTwo="Predict Business model"
            subHeadingCircleTwo="Part 13"
          
            last={true}
          />
        </div>
        <div className="  mt-[100px] w-full z-4 px-[10%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            key={3}
            part={part13}
            nextPart={part14}
            headingCircleOne="Validate your financial model”"
            subHeadingCircleOne="Part 14"
            headingCircleTwo="Setup sales motion & marketing"
            subHeadingCircleTwo="Part 15"
        
            last={true}
          />
        </div>
        <div className=" mt-[100px] w-full z-4 px-[10%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            part={part15}
            nextPart={part16}
            headingCircleOne="Scale founder skills”"
            subHeadingCircleOne="Part 16"
            headingCircleTwo="Scale sales function"
            subHeadingCircleTwo="Part 17"
            last={true}
          />
        </div>
        <div className="  mt-[100px] w-full z-4 px-[10%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            part={part17}
            nextPart={part18}
            headingCircleOne="Scale marketing efforts"
            subHeadingCircleOne="Part 18"
            headingCircleTwo="Scale marketing efforts"
            subHeadingCircleTwo="Part 18"
            last={true}
          />
        </div>
        <div className="  mt-[100px] w-full z-4 px-[10%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            part={part19}
            nextPart={part20}
            headingCircleOne="Scale Finance & Accounting functions "
            subHeadingCircleOne="Part 19"
            headingCircleTwo="Scale Human Capital"
            subHeadingCircleTwo="Part 20"
            last={true}
          />
        </div>
        <div className="  mt-[100px] w-full z-4 px-[10%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            key={3}
            part={part21}
            nextPart={part22}
            headingCircleOne="Scale Product Functions"
            subHeadingCircleOne="Part 21"
            headingCircleTwo="Scale IT/Dev functions"
            subHeadingCircleTwo="Part 22"
            last={true}
          />
        </div>
        <div className="  mt-[100px] w-full z-4 px-[10%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            part={part23}
            nextPart={part24}
            headingCircleOne="Scale client support"
            subHeadingCircleOne="Part 23"
            headingCircleTwo="Scale Operations"
            subHeadingCircleTwo="Part 24"
            last={true}
          />
        </div>
        <div className="  mt-[100px] w-full z-4 px-[10%] pl-[20%] z-4">
          <TrackorComponent
            showFirst={false}
            part={part25}
            nextPart={part26}
            headingCircleOne="Scale Operations"
            subHeadingCircleOne="Part 24"
            headingCircleTwo="Scale Operations"
            subHeadingCircleTwo="Part 24"
            last={false}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
