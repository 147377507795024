import PlaybookRowOne from "./PlaybookRowOne";
import PlaybookRowTwo from "./PlaybookRowTwo";

const TrackorComponent = ({part,nextPart,showFirst,last,headingCircleOne,subHeadingCircleOne,headingCircleTwo,subHeadingCircleTwo}) => {
  
  return (
    <div>
      <div className="relative">
        <div className="flex flex-row items-center w-full justify-start">
          <div
            className={`${
              showFirst ===true
                ? "max-w-[120px] max-h-[120px] min-w-[120px]  z-10 rounded-full border-[2px] border-white  "
                : "max-w-[0px] h-full overflow-hidden -mt-[19px]"
            } min-h-[120px] grid grid-cols-1 `}
          >
            <h1 className=" col-span-1 flex justify-center items-center bg-[#6CA6C4] rounded-tr-full rounded-tl-full text-center text-xs p-2 text-white border-b-white border-b-[2px]">
              Start with Problem
            </h1>
            <h2 className=" bg-[#D9D9D9] rounded-br-full rounded-bl-full col-span-1 flex justify-center items-center p-2 text-[#20556E] font-semibold text-xl">
              Part 1
            </h2>
          </div>
          <PlaybookRowOne blocks={part}  headingCircleOne={headingCircleOne} subHeadingCircleOne={subHeadingCircleOne}/>
          
        </div>
         <PlaybookRowTwo blocks={nextPart} last={last} headingCircleTwo={headingCircleTwo} subHeadingCircleTwo={subHeadingCircleTwo}/>
         {/* <div className="mt-[100px] flex justify-between items-center w-full">
          <div className="bg-tracker-line-1 h-12 w-full flex flex-row items-center justify-end">
            <div className="bg-tracker-circle-1 relative mr-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2">
              10
              <div className="absolute left-0 top-[55px]">
                <h1
                  style={{
                    color: props?.data?.block10?.textColor,
                    fontSize: "14px",
                  }}
                  className={`absolute w-[140px] left-10  underline underline-offset-8`}
                >
                  {props?.data?.block10?.text}
                </h1>
                <div
                  style={{ background: props?.data?.block10?.lineColor }}
                  className={`w-[3px]  h-[52px] ml-5`}
                ></div>
              </div>
            </div>
            <div className="bg-tracker-arrow-2 mr-[1px] z-20 h-12 w-12 custom-arrow -rotate-90"></div>
          </div>
          <div className="bg-tracker-line-2 h-12 w-full flex flex-row items-center justify-end">
            <div className="bg-tracker-circle-2 relative mr-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2">
              09
              <div className=" absolute left-0 -top-[60px]">
                <h1
                  style={{
                    color: props?.data?.block9?.textColor,
                    fontSize: "14px",
                  }}
                  className={`absolute w-[120px] right-2 -top-2 underline underline-offset-8`}
                >
                  {props?.data?.block9?.text}
                </h1>
                <div
                  style={{ background: props?.data?.block9?.lineColor }}
                  className={`w-[3px]  h-[52px] ml-5`}
                ></div>
              </div>
            </div>
            <div className=" bg-tracker-arrow-1 mr-[1px] z-20 h-12 w-12 custom-arrow -rotate-90"></div>
          </div>
          <div className="bg-tracker-line-1 h-12 w-full flex flex-row items-center justify-end">
            <div className="bg-tracker-circle-3 relative mr-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2">
              08
              <div className="absolute left-0 top-[55px]">
                <h1
                  style={{
                    color: props?.data?.block8?.textColor,
                    fontSize: "14px",
                  }}
                  className={`absolute w-[140px] left-10  underline underline-offset-8`}
                >
                  {props?.data?.block8?.text}
                </h1>
                <div
                  style={{ background: props?.data?.block8?.lineColor }}
                  className={`w-[3px]  h-[52px] ml-5`}
                ></div>
              </div>
            </div>
            <div className="bg-tracker-arrow-2 mr-[1px] z-20 h-12 w-12 custom-arrow -rotate-90"></div>
          </div>

          <div className="bg-tracker-line-2 h-12 w-full flex flex-row items-center justify-end">
            <div className="bg-tracker-circle-2 relative mr-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2">
              07
              <div className=" absolute left-0 -top-[60px]">
                <h1
                  style={{
                    color: props?.data?.block7?.textColor,
                    fontSize: "14px",
                  }}
                  className={`absolute w-[140px] right-2 -top-2 underline underline-offset-8`}
                >
                  {props?.data?.block7?.text}
                </h1>
                <div
                  style={{ background: props?.data?.block7?.lineColor }}
                  className={`w-[3px]  h-[52px] ml-5`}
                ></div>
              </div>
            </div>
            <div className="bg-tracker-arrow-1 mr-[1px] z-20 h-12 w-12 custom-arrow -rotate-90"></div>
          </div>
          <div className="bg-tracker-line-1 h-12 w-full flex flex-row items-center justify-end">
            <div className="bg-tracker-circle-1 relative mr-5 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2">
              06
              <div className="absolute left-0 top-[55px]">
                <h1
                  style={{
                    color: props?.data?.block6?.textColor,
                    fontSize: "14px",
                  }}
                  className={`absolute  w-[140px] left-10  underline underline-offset-8 `}
                >
                  {props?.data?.block6?.text}
                </h1>
                <div
                  style={{ background: props?.data?.block6?.lineColor }}
                  className={`w-[3px]  h-[52px] ml-5`}
                ></div>
              </div>
            </div>
          </div>
          <div className=" absolute  top-[370px] flex flex-row items-center justify-end rotate-180">
            <div className=" absolute -right-[88px] -top-[168px]">
              <img className="h-[400px]" src="/1ST.svg" alt="curve" />
              <div className="bg-tracker-arrow-1 absolute right-[41px] top-[271px] ml-[0.5px] z-20 h-11 w-12 custom-arrow rotate-90"></div>

              {props.last && (
                <div
                  className={`rotate-180 absolute -right-[40px] top-[156px] z-10 rounded-full border-[2px] border-white max-w-[120px] max-h-[120px] min-w-[120px] min-h-[120px] grid grid-cols-1`}
                >
                  <h1 className=" col-span-1 flex justify-center items-center bg-[#386FA0] rounded-tr-full rounded-tl-full text-center text-xs p-2 text-white border-b-white border-b-[2px]">
                    Exploring Plan the Mission-E2
                  </h1>
                  <h2 className=" bg-[#D9D9D9] rounded-br-full rounded-bl-full col-span-1 flex justify-center items-center p-2 text-[#386FA0] font-semibold text-xl">
                    Part 3
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>  */}
      </div>
    </div>
  );
};

export default TrackorComponent;
