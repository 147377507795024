import React from 'react'

const DataNotAvailable = (props) => {
  return (
    <div className='m-8 bg-white h-[500px] flex flex-col justify-center items-center gap-4 rounded-md'>
        
            <img src="/newCreationicon.svg" alt="icon" />
            <detail className="text-black text-opacity-70 ">{props.text}</detail>
            <detail className="text-black text-opacity-70 text-sm">{props.subText}</detail>
            <button className='primary py-2 px-3 cursor-pointer transition-shadow ' onClick={props.handleClick}>{props.buttontext}</button>

        

    </div>
  )
}

export default DataNotAvailable;
