import React from 'react'
import { TopBar } from '../../../components/CommonComponent/Topbar';
import Section from '../../../components/AvatareNewFlow/Section';

const SparkAndExploreItems = [
    { number: "01", title: "Identify Problems & Trends" },
    { number: "02", title: "Evaluate Problems & Trends" },
    { number: "03", title: "Select the Focus Problem" },
    { number: "04", title: "Identify Pain Points" },
    { number: "05", title: "Determine Jobs to be Done" },
    { number: "06", title: "Profitable Market Need" },
    { number: "07", title: "Identify a Potential Solution to the Problem" },
    { number: "08", title: "Strategic Innovation Statement" },
    { number: "09", title: "Idea Testing with Mini Focus Group" },
    { number: "10", title: "Quick Market Analysis" },
    { number: "11", title: "Entrepreneur Essentials" },
    { number: "12", title: "Document and Define the Company's Overall Vision, Mission, and Core Values" },
    { number: "13", title: "Basic Project Management" },
    { number: "14", title: "Keep Separate Spending Records" },
    { number: "15", title: "Secure Mentorship" },
    { number: "16", title: "Surround Yourself with 6 Key Advisors" },
    // Add the rest of the items here...Master Founder Fundamental
  ];
  
  const QuestionAndUncover = [
    { number: "17", title: "Identify the Required Features for a Minimum Viable Product (MVP)" },
    { number: "18", title: "Feedback Loop for Required Features of MVP" },
    { number: "19", title: "Primary Research on Market Size - Identify Total Addressable Market (TAM)" },
    { number: "20", title: "Competitive Analysis" },
    { number: "21", title: "Primary Research on Market Characteristics" },
    { number: "22", title: "Decide on One of 'Three Horizons'" },
    { number: "23", title: "10 Types of Innovation" },
    { number: "24", title: "Create a Customer Avatar" },
    { number: "25", title: "Identify Pilot Clients" },
    { number: "26", title: "Calculate the Cost for MVP Development" },
    { number: "27", title: "Select a Funding Option" },
    { number: "28", title: "Define the Unique Selling Propositions (USP's)" },
    { number: "29", title: "Understand the Customer Buying Process" },
    { number: "30", title: "Create Roadmap for MVP Development" },
    { number: "31", title: "Establish Designs and Wireframes/Prototypes" },
    { number: "32", title: "Develop the Minimal Viable Product (MVP) or a Prototype to Show Potential Customers" },
    { number: "33", title: "Product Testing (Customer) with Feedback Collection" },
    { number: "34", title: "Map the Customer Use Journey" },
    { number: "35", title: "Refine MVP Based on Customer Feedback" },
    { number: "36", title: "Validate Customer Avatar(s)" },
    { number: "37", title: "Business Setup" },
    { number: "38", title: "Assessing Intellectual Property" },
    { number: "39", title: "Prepare Tech Infrastructure and Security" },
    { number: "40", title: "Finalize Your CAP Table" },
    { number: "41", title: "Create Org Chart" },
    { number: "42", title: "Establish Operations Agreement" },
    { number: "43", title: "Round Out the Founding Team" },
    { number: "44", title: "Set Up Employee Participation Program (If Applicable)" },
    // Add the rest of the items here...
  ];
  const MarketStrategy = [
    { number: "45", title: "Determine Your Competitive Moat(s)" },
    { number: "46", title: "Create the Business Model Canvas" },
    { number: "47", title: "Define Your Brand" },
    { number: "48", title: "Establish an Online Footprint" },
    { number: "49", title: "Ensure Environmental, Social, & Governance (ESG) Compliance (If Applicable)" },
    { number: "50", title: "Identify All the Players in Your Ecosystem" },
    { number: "51", title: "Build Supply Chain (If Applicable)" },
    { number: "52", title: "Test Distribution Stream" },
    { number: "53", title: "Identify and Approach Pilot Partnerships" },
    { number: "54", title: "Consider Pre-Selling Your Product" },
    { number: "55", title: "Create a Customer Feedback Mechanism (Systemized and Automatic)" },
    { number: "56", title: "Stress Test and Bug Fix" },
    { number: "57", title: "Re-work MVP into a Final Product Based on Customer Feedback" },
    { number: "58", title: "Continue Testing and Bug-Fixing (UAT Testing)" },
    { number: "59", title: "Set Up Lean PMO" },
    { number: "60", title: "Develop Generic External SOP's" },
  ];

  const Collaborate =[
    { number: "61", title: "Identify Potential Business Models" },
    { number: "62", title: "Rank Business Models on “Short List”" },
    { number: "63", title: "Test Business Model with Customer Feedback" },
    { number: "64", title: "Build Financial Model" },
    { number: "65", title: "Create Accelerator Roadmap" },
    { number: "66", title: "Design Operating Model" },
    { number: "67", title: "Define the Top 20 KPI" },
    { number: "68", title: "Determine & Deploy Tool Stack" }, 
    { number: "69", title: "Set Up Data Warehouse (if applicable)" },
    { number: "70", title: "Prepare Daily, Weekly, and Monthly Reports" },
    { number: "71", title: "Create a Product Marketing Plan" },
    { number: "72", title: "Build Content Generator" },
    { number: "73", title: "Build Sales Funnel" },
    { number: "74", title: "Set Up Customer Care" },
  ]
  
  const ScaleItExit = [
    
    { number: "75", title: "Constantly Evaluate Further Growth and Expansion Options" },
    { number: "76", title: "Create Entrepreneur Scorecard" },
    { number: "77", title: "Strategies to guide growth throughout your business" },
    { number: "78", title: "Create Personal AI Bot" },
    { number: "79", title: "Improve Management of the Sales Funnel" },
    { number: "80", title: "Define & Implement Best Practices for Each Function: Sales" },
    { number: "81", title: "Improve and Revise Your Sales Playbook" },
    { number: "82", title: "Create Your Sales Team Template" },
    { number: "83", title: "Create a Follow-Up Machine" },
    { number: "84", title: "Create Revenue Multiplier" },
    { number: "85", title: "Define & Implement Best Practices for Each Function: Marketing" },
    { number: "86", title: "Create Content Pipeline" },
    { number: "87", title: "Create Lead Generator Outline" },
    { number: "88", title: "Prepare Press List" },
    { number: "89", title: "Launch PR and Paid Marketing Campaign" },
    { number: "90", title: "Build Demo Demand Sequence" },
    { number: "91", title: "Build Social Proof" },
    { number: "92", title: "Build Webinar Converter (If Applicable)" },
    { number: "93", title: "Build Trial Closer (If Applicable)" },
    { number: "94", title: "Focus on Cross-Channel Marketing Mix that Works" },
    { number: "95", title: "Build Brand and Execute PR Strategy" },
    { number: "96", title: "Define & Implement Best Practices for Each Function: Finance & Accounting" },
    { number: "97", title: "Establish Proper Financial Compliance" },
    { number: "98", title: "Ramp Up Accounting Systems" },
    { number: "99", title: "Analyze Progress Toward Financial Targets" },
    { number: "100", title: "Optimize Customer Acquisition Costs (CAC) vs. Customer Lifetime Value (CLV)" },
    { number: "101", title: "Define & Implement Best Practices for Each Function: Human Capital" },
    { number: "102", title: "Fill Out Org Chart/Hiring" },
    { number: "103", title: "Refine and Improve SOPs" },
    { number: "104", title: "Evaluate Pay Scales and Human Capital" },
    { number: "105", title: "Create and Maintain Company Culture" },
    { number: "106", title: "Implement Ongoing Knowledge Sharing" },
    { number: "107", title: "Define & Implement Best Practices for Each Function: Product" },
    { number: "108", title: "Analyze Customer Engagement with the Product" },
    { number: "109", title: "Product Roadmap" },
    { number: "110", title: "Enhance UI/UX According to Usability Tests" },
    { number: "111", title: "Achieve Product-Market-Fit" },
    { number: "112", title: "Define & Implement Best Practices for Each Function: IT & Dev" },
    { number: "113", title: "Engage Third Party Auditors for SOC, PCI, PEN Test, etc." },
    { number: "114", title: "Improve Tech Stack’s Scalability, Availability, Speed, and Security" },
    { number: "115", title: "Define & Implement Best Practices for Each Function: Customer Support" },
    { number: "116", title: "Improve Customer Care Processes to Maximize NPS" },
    { number: "117", title: "Define & Implement Best Practices for Each Function: Operations" },
    { number: "118", title: "Build Local Logistics Value Streams " },
    { number: "119", title: "Increase Facility Capacity" },
    { number: "120", title: "Perform Legal Check of Business Model and Key Documents" },
    { number: "121", title: "Dig Deeper Using Ad-hoc Reports for Each Function" },
    { number: "122", title: "Navigate Using Daily, Weekly, and Monthly Reports" },
    { number: "123", title: "Re-design the Operating Model According to the Data" },
    { number: "124", title: "Eliminate Operational Bottlenecks" },
    { number: "125", title: "Re-assess Suppliers and Partners" },
    { number: "126", title: "Enhance Payment Mix, Fees, Checkout Funnel, and Fraud Prevention" },
    { number: "127", title: "Optimize CRM" },
    { number: "128", title: "Automate Important Manual Processes" },
    { number: "129", title: "Boost Workforce Agility" },
    { number: "130", title: "Implementing the OKR Framework" },


  ];

 
  


const TemplatesLibrary = () => {
    const [avatares, setAvatares] = React.useState([]);
    const [rerender, setRerender] = React.useState(false);
  
    const [searchQuery, setSearchQuery] = React.useState("");
  
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };
  
    const filterItems = (items) => {
      return items.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    };
  
    let userId = localStorage.getItem("UserId");
    const [UserId, setUserId] = React.useState(userId);
    // console.log("UserId :",UserId);
  
    const getData = async () => {
      return fetch(
        `${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?UserId=${UserId}`,
        { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
      ).then((data) => data.json());
    };
    React.useEffect(() => {
      getData().then((items) => {
        if (items.message != "No Avatars Yet") {
          setAvatares(items.message);
        } else if ((items.message = "No Avatars Yet")) {
          setAvatares(items.message);
        }
      });
    }, [rerender, UserId]);
  return (
    <div className="min-h-screen">
      <div>
        <div className="flex flex-col">
          <div className="w-full">
            <TopBar text="Templates Library" />
            <div className="p-8">
              <div className="flex w-full justify-between items-center">
                <h2 className="text-2xl font-medium text-black">
                  Template-based Avatare
                </h2>
                <div>
                  <div className="relative w-[350px] bg-[#F7F8FC] rounded-[4px]">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <img src="/search.svg" />
                    </div>
                    <input
                      type="text"
                      syle={{ borderColor: "rgba(0, 0, 0, 0.38)" }}
                      placeholder="Search template here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="w-full p-2 pl-10 border bg-[#F7F8FC]  custom-outline"
                    />
                  </div>
                </div>
              </div>
              <p className=" text-black text-opacity-70 font-normal mb-2">
                Select from the below templates to get it started
              </p>

              <Section
                heading="Spark & Explore"
                items={filterItems(SparkAndExploreItems)}
                backGround="linear-gradient(180deg, #7FB04F 0%, #497964 100%)"
                CardBg="rgba(127, 176, 79, 0.12)"
                border="1px solid #1F6415"
                boxShadow="0px -5px 18px 0px rgba(0, 0, 0, 0.25) inset"
              />
              <Section
                heading="Questions & Uncover"
                items={filterItems(QuestionAndUncover)}
                backGround="var(--Turquise, #6CA6C4)"
                CardBg="rgba(108, 166, 196, 0.12)"
                border="1px solid #64A1D6"
                boxShadow="0px -5px 18px 0px rgba(0, 0, 0, 0.25) inset"
              />
              <Section
                heading="Experiment & Network"
                items={filterItems(MarketStrategy)}
                backGround="var(--Fern, #497964)"
                CardBg="rgba(73, 121, 100, 0.12)"
                border="1px solid #497964"
                boxShadow="0px -5px 18px 0px rgba(0, 0, 0, 0.25) inset"
              />
              <Section
                heading="Collaborate"
                items={filterItems(Collaborate)}
                backGround="linear-gradient(180deg, #424344 0%, #C6C3C7 100%"
                CardBg="rgba(66, 67, 68, 0.12)"
                border="1px solid #424344"
                boxShadow="0px -5px 18px 0px rgba(0, 0, 0, 0.25) inset"
              />
              <Section
                heading="Exit"
                items={filterItems(ScaleItExit)}
                backGround=" var(--CERULEAN, #20556E)"
                CardBg="rgba(32, 85, 110, 0.12)"
                border="1px solid #20556E"
                boxShadow="0px -5px 18px 0px rgba(0, 0, 0, 0.25) inset"
              />
              {/* <Section
                heading="Raising the Capital"
                items={filterItems(raiseCapitalItems)}
                backGround="var(--Kiwi, #7FB04F"
                CardBg="rgba(127, 176, 79, 0.12"
                border="1px solid #7FB04F"
                boxShadow="0px -5px 18px 0px rgba(0, 0, 0, 0.25) inset"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplatesLibrary